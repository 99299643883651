.list-item {
    display        : flex;
    height         : 100%;
    /* horizontal alignment */
    justify-content: flex-start;
    /* vertical aligement */
    align-items    : center;
}

.list-item.centered {
    justify-content: center;
}

.list-item>* {
    margin: 0 calc(var(--padding)/2) !important;
}

.list-item.tight>* {
    margin: 0 calc(var(--padding)/8) !important;
}

.list-item>*:first-child {
    margin-left: 0rem !important;
}

.list-item>*:last-child {
    margin-right: 0rem !important;
}

.list-item>.stretched {
    flex: 1;
}

.list-item>.stretched.double {
    flex: 2;
}

.list-item>.spacer {
    flex: auto;
}

.list-item>.truncate-text {
    white-space  : nowrap;
    overflow     : hidden;
    text-overflow: ellipsis;
}

.list-item>.quarter {
    width: 25%;
}

.list-item>.half {
    width: 50%;
}

.list-item>.sm {
    width: 4rem;
}

.list-item>.md {
    width: 8rem;
}

.list-item>.lg {
    width: 12rem;
}

.list-item>.xl {
    width: 20rem;
}

.list-item>.centered {
    text-align: center;
}