/* .bccampus-navigation.horizontal {} */
.bccampus-navigation.vertical {
    text-align: center;
}

.bccampus-navigation-item {
    color: var(--app-text-color);
}

.bccampus-navigation-item:hover {
    color: var(--app-hover-color);
}

.bccampus-navigation.horizontal>.bccampus-navigation-item {
    padding: calc(var(--padding)/2) 0em;
    margin : 0em 1em;
}

.bccampus-navigation.vertical>.bccampus-navigation-item {
    display    : block;
    font-Size  : 1em;
    line-height: 2em;
    margin     : 1em 0em;
    padding    : var(--padding)
}


.bccampus-navigation.vertical>.bccampus-navigation-item>.anticon {
    display  : block;
    font-size: 1.75em;
}

.bccampus-navigation-item.active {
    color      : var(--app-primary-color);
}

.bccampus-navigation.horizontal>.bccampus-navigation-item.active {
    border-bottom: 4px solid var(--app-primary-color);
}

.bccampus-navigation.vertical>.bccampus-navigation-item.active {
    border-right: 4px solid var(--app-primary-color);
}