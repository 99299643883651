#app {
    width           : 100%;
    height          : 100%;
    overflow        : hidden;
    background-color: var(--app-background-color);
}

#app-sidebar {
    width   : var(--app-sidebar-width);
    height  : 100%;
    position: absolute;
    overflow: hidden;
    z-index : 10;
}

#app-sidebar.bordered {
    border-right: var(--app-border-style)
}

#app-bar {
    height  : var(--app-bar-height);
    width   : 100%;
    position: absolute;
    overflow: hidden;
    z-index : 10;
}

#app-bar.bordered {
    border-bottom: var(--app-border-style)
}

#app-sidebar>* {
    height: 100%;
}

#app-bar+#app-sidebar {
    height    : calc(100% - var(--app-bar-height));
    margin-top: var(--app-bar-height);
}

#app-sidebar+#app-bar {
    width      : calc(100% - var(--app-sidebar-width));
    margin-left: var(--app-sidebar-width);
}

#app-content {
    width     : 100%;
    height    : calc(100% - var(--app-bar-height));
    margin-top: var(--app-bar-height);
    position  : absolute;
    overflow  : auto;
}

#app-sidebar+#app-content {
    width      : calc(100% - var(--app-sidebar-width));
    margin-left: var(--app-sidebar-width);
}


.content-sidebar {
    height  : 100%;
    width   : var(--content-sidebar-width);
    overflow: hidden;
    position: fixed;
}

.content-sidebar.slim {
    width: 3.5rem;
}

.content-sidebar.bordered {
    border-right: var(--app-border-style);
}

.content-sidebar.slim .icon {
    font-size: 1.5rem;
    margin   : 0rem 0rem var(--padding) 0rem !important;
}

.content-sidebar~* {
    width      : calc(100% - var(--content-sidebar-width)) !important;
    margin-left: var(--content-sidebar-width) !important;
}

.content-sidebar.slim~* {
    width      : calc(100% - 3.5rem);
    margin-left: 3.5rem;
}

.content-sidebar>* {
    height: 100%;
}

.content-menu {
    width           : 100%;
    height          : var(--content-menu-height);
    top             : 0px;
    background-color: var(--app-background-color);
    position        : sticky;
    overflow        : hidden;
    z-index         : 9;
    padding         : var(--padding) calc(var(--padding)*2);
}

.content-menu.bordered {
    border-bottom: var(--app-border-style)
}

.content-container {
    width   : 100%;
    height  : 100%;
    overflow: hidden;
}

.content-container.scrollable {
    overflow: auto;
}

.content-menu+.content-container {
    height: calc(100% - var(--content-menu-height));
}

.content-container.padded {
    padding: var(--padding) calc(var(--padding)*2);
}

@media (min-width: 1366px) {
    .content-container.responsive {
        width : 1366px;
        margin: auto;
    }
}