@import '~antd/dist/antd.less';

:root {
  --app-background-color    : @component-background;
  --app-primary-color       : @primary-color;
  --app-alternate-color     : #1890ff;
  --app-secondary-color     : #F9F5FF;
  --app-primary-shadow-color: tint(@primary-color, 80%);
  --app-success-color       : @success-color;
  --app-error-color         : @error-color;
  --app-error-shadow-color  : tint(@error-color, 80%);
  --app-warning-color       : @warning-color;
  --app-text-color          : @text-color;
  --app-hover-color         : @primary-5;
  --padding                 : 1em;
  --app-bar-height          : 75px;
  --content-menu-height     : 65px;
  --app-sidebar-width       : 100px;
  --content-sidebar-width   : 90px;
  --app-border-style        : @border-width-base @border-style-base @border-color-base;
  --app-border-radius       : @border-radius-base;
}

#root {
  width : 100%;
  height: 100%;
}

.bccampus-logo {
  fill: var(--app-text-color);
}

.bccampus-button-ghost {
  border-color: var(--app-primary-color);
  color       : var(--app-primary-color);
}

.bccampus-button-ghost:hover {
  background-color: var(--app-hover-color);
  color           : var(--app-background-color);
}

ul.bccampus-list,
ol.bccampus-list {
  padding: 0em 0em 0em 1.5em;
  margin : 0em;
}

ul.bccampus-list li+li,
ol.bccampus-list li+li {
  padding-top: 0.25em;
}

@primary-color: #652CB3;@border-radius-base: 4px;@table-selected-row-bg: #F9F5FF;@text-color: #343434;@text-color-secondary: #767676;